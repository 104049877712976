import * as React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import axios from "axios";

import firebase from "../../config/firebase";

import SpinnerIcon from "../components/spinner";
import Logo from "../images/logo.svg";

// markup
const RegisterPage = () => {
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [disableSocialAuth, setDisableSocialAuth] = React.useState(false);
  const [isGoogleOauthLoading, setIsGoogleOauthLoading] = React.useState(false);
  const [isTwitterOauthLoading, setIsTwitterOauthLoading] =
    React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();

  const email = watch("email", "");
  const password = watch("password", "");
  const handleCreateAccount = ({ email, username, password }) => {
    try {
      setIsButtonLoading(true);
      axios
        .post(`${process.env.GATSBY_API_BASE}/usernameCheck`, {
          username,
        })
        .then(() => {
          setIsButtonLoading(true);
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async (response) => {
              let idToken = await response.user?.getIdTokenResult();

              if (idToken) {
                axios
                  .post(
                    `${process.env.GATSBY_API_BASE}/register`,
                    {
                      username,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${idToken.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    navigate("/residential/rotating");
                  });
              }
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;

              if ("auth/email-already-in-use" === errorCode) {
                setError(
                  "email",
                  {
                    type: "api",
                    message: errorMessage,
                  },
                  {
                    shouldFocus: true,
                  }
                );
              }

              if ("auth/weak-password" === errorCode) {
                setError(
                  "password",
                  {
                    type: "api",
                    message: errorMessage,
                  },
                  {
                    shouldFocus: true,
                  }
                );
              }
            })
            .finally(() => {
              setIsButtonLoading(false);
            });
        })
        .catch(() => {
          setError(
            "username",
            {
              type: "api",
              message:
                "Username is already in use, please try with different name",
            },
            {
              shouldFocus: true,
            }
          );
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    } catch (e) {
      console.log("Signup Failed");
    }
  };

  const handleLoginWithGoogle = (e) => {
    e.preventDefault();
    setIsGoogleOauthLoading(true);
    setDisableSocialAuth(true);
  };

  const handleLoginWithTwitter = (e) => {
    e.preventDefault();
    setIsTwitterOauthLoading(true);
    setDisableSocialAuth(true);
  };
  return (
    <div className="account-login section">
      <Helmet>
        <title>Create account</title>
      </Helmet>
      <div className="container">
        <div className="mb-30">
          <img className="h-12 w-auto m-auto" src={Logo} alt="ProxyGrid" />
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-9 col-12">
            <form
              className="card login-form inner-content"
              onSubmit={handleSubmit(handleCreateAccount)}
            >
              <div className="card-body">
                <div className="title">
                  <h3>Create an account</h3>
                </div>
                {/*
                <div className="social-auth">
                  <button
                    className={`btn alt${
                      isGoogleOauthLoading ? " loading" : ""
                    }${disableSocialAuth ? " disabled" : ""}`}
                    onClick={handleLoginWithGoogle}
                  >
                    {isGoogleOauthLoading && <SpinnerIcon />}
                    {!isGoogleOauthLoading && (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.9993 10.8175C20.9993 10.0987 20.9402 9.37606 20.8143 8.66895H10.7109V12.7407H16.4966C16.2566 14.0539 15.4851 15.2155 14.3555 15.9537V18.5957H17.8073C19.8343 16.7541 20.9993 14.0344 20.9993 10.8175Z"
                          fill="#4285F6"
                        ></path>
                        <path
                          d="M10.7125 21.1481C13.6014 21.1481 16.0377 20.2118 17.8127 18.5955L14.361 15.9536C13.4007 16.5985 12.1609 16.9637 10.7165 16.9637C7.92204 16.9637 5.55268 15.1027 4.70254 12.6006H1.14062V15.3241C2.95897 18.8947 6.66258 21.1481 10.7125 21.1481Z"
                          fill="#34A843"
                        ></path>
                        <path
                          d="M4.69751 12.6006C4.24882 11.2874 4.24882 9.86538 4.69751 8.55217V5.82861H1.13945C-0.379815 8.81636 -0.379815 12.3364 1.13945 15.3241L4.69751 12.6006Z"
                          fill="#FBBC14"
                        ></path>
                        <path
                          d="M10.7127 4.18514C12.2398 4.16183 13.7157 4.72908 14.8217 5.77033L17.8799 2.75148C15.9434 0.956487 13.3733 -0.0303695 10.7127 0.000712552C6.66265 0.000712552 2.959 2.25416 1.14062 5.82861L4.69865 8.55217C5.54486 6.04618 7.91819 4.18514 10.7127 4.18514Z"
                          fill="#EA4345"
                        ></path>
                      </svg>
                    )}
                    Continue with Google
                  </button>
                  <button
                    className={`btn alt${
                      isTwitterOauthLoading ? " loading" : ""
                    }${disableSocialAuth ? " disabled" : ""}`}
                    onClick={handleLoginWithTwitter}
                  >
                    {isTwitterOauthLoading && <SpinnerIcon />}
                    {!isTwitterOauthLoading && (
                      <svg
                        fill="#1DA1F2"
                        width="22"
                        height="22"
                        version="1.1"
                        id="lni_lni-twitter-original"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 64 64"
                        xmlSpace="preserve"
                      >
                        <path
                          d="M20.3,57.4c23.6,0,36.4-19.5,36.4-36.4c0-0.4,0-1.1-0.1-1.7c2.5-1.8,4.7-4.1,6.4-6.6c-2.4,1.1-4.8,1.7-7.3,2
	c2.7-1.6,4.7-4.1,5.6-7.1c-2.5,1.4-5.1,2.5-8.2,3.1c-2.4-2.5-5.6-4.1-9.3-4.1c-7.1,0-12.9,5.8-12.9,12.9c0,1,0.1,2,0.3,3
	C20.9,21.8,11.5,16.7,5.1,9c-1.1,2-1.7,4.1-1.7,6.4c0,4.5,2.3,8.3,5.8,10.6c-2.1-0.1-4.1-0.7-5.8-1.6c0,0.1,0,0.1,0,0.1
	c0,6.1,4.4,11.4,10.2,12.6c-1.1,0.3-2.3,0.4-3.2,0.4c-0.8,0-1.7-0.1-2.4-0.3c1.7,5.1,6.4,8.8,12,8.9c-4.4,3.4-9.9,5.5-15.8,5.5
	C3,51.8,2,51.6,1,51.5C6.4,55.3,13.1,57.4,20.3,57.4"
                        />
                      </svg>
                    )}
                    Continue with Twitter
                  </button>
                </div>
                <div className="or-devider">
                  <span>Or</span>
                </div>
                */}
                <div className="input-head">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        className={`form-control ${
                          errors.email ? "error" : ""
                        }`}
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Entered value does not match email format",
                          },
                        })}
                      />
                    </div>
                    {errors.email && (
                      <span role="alert">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        className={`form-control ${
                          errors.username ? "error" : ""
                        }`}
                        type="text"
                        id="username"
                        placeholder="Enter your username"
                        {...register("username", {
                          required: "Username is required",
                          minLength: {
                            value: 4,
                            message:
                              "Username should at least contain 4 characters",
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9_.-]*$/,
                            message:
                              "Entered value does not match username format",
                          },
                        })}
                      />
                    </div>
                    {errors.username && (
                      <span role="alert">{errors.username.message}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        className={`form-control ${
                          errors.password ? "error" : ""
                        }`}
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                    </div>

                    {errors.password && (
                      <span role="alert">{errors.password.message}</span>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between bottom-content">
                  <p>
                    By signing up you agree to the Terms of Service and Privacy
                    Policy
                  </p>
                </div>
                <div className="button">
                  <button
                    className={`btn${email && password ? "" : " disabled"}`}
                    type="submit"
                  >
                    {isButtonLoading && <SpinnerIcon />}
                    Create account
                  </button>
                </div>
                <p className="alt-text">
                  Already have an account? <Link to="/login">Sign in</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
